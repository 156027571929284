const STATE_PACKAGE_TITLE = 'State'
const EVENT_PACKAGE_TITLE = 'Event'
const DIRECTIVE_PACKAGE_TITLE = 'Directive'

const STATE_PACKAGE_DESC1 = 'статус'
const EVENT_PACKAGE_DESC1 = 'подія'
const DIRECTIVE_PACKAGE_DESC1 = 'команда'

const STATE_PACKAGE_DESC2 = 'статуса'
const EVENT_PACKAGE_DESC2 = 'події'
const DIRECTIVE_PACKAGE_DESC2 = 'команди'

const STATE_PACKAGE_DESC3 = 'статус'
const EVENT_PACKAGE_DESC3 = 'подію'
const DIRECTIVE_PACKAGE_DESC3 = 'команду'

export const PACKAGE_TYPES = Object.freeze([
  {
    title: STATE_PACKAGE_TITLE,
    desc1: STATE_PACKAGE_DESC1,
    desc2: STATE_PACKAGE_DESC2,
    desc3: STATE_PACKAGE_DESC3,
    type: 1,
  },
  {
    title: EVENT_PACKAGE_TITLE,
    desc1: EVENT_PACKAGE_DESC1,
    desc2: EVENT_PACKAGE_DESC2,
    desc3: EVENT_PACKAGE_DESC3,
    type: 2,
  },
  {
    title: DIRECTIVE_PACKAGE_TITLE,
    desc1: DIRECTIVE_PACKAGE_DESC1,
    desc2: DIRECTIVE_PACKAGE_DESC2,
    desc3: DIRECTIVE_PACKAGE_DESC3,
    type: 3,
  },
])

export const EVENT_TYPE = 2
export const STATUS_TYPE = 1
export const COMMAND_TYPE = 3
