<template>
  <v-container fluid>
    <v-card v-if="isLoaded">
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>
          Ви {{ isEditable ? ' редагуєте' : ' додаєте' }} {{ selectedPackage.type | getPackageDesc3 }} в
          {{ isEditable ? 'групі' : 'групу' }} "{{ additionalInfoSelectedEventType.name[$currentLocale] }} ({{
            additionalInfoSelectedEventType.code
          }})"
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">
          Картка події або статусу
          <sup>
            <v-btn href="https://vland.atlassian.net/wiki/spaces/CMDocs/pages/896303117" target="_blank" icon small>
              <v-icon size="18" color="primary">mdi-information-outline</v-icon>
            </v-btn>
          </sup>
        </div>
      </v-toolbar>

      <v-card flat color="blue-grey lighten-5">
        <Event @selectPackage="getPackage" @saveData="saveData" :isEditable="isEditable"></Event>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import ConvertPackagesTypes from '@/mixins/convertPackagesTypes'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'InfoConstructEvent',

  mixins: [ConvertPackagesTypes],

  components: {
    BackButton,
    Event: () => import('@/components/support/info_constructor/events_statuses/EventConstructor'),
  },

  data() {
    return {
      ROUTER_LINKS: ROUTER_LINKS,
      currentTab: null,
      isLoaded: false,
      selectedPackage: {
        type: 2,
      },
    }
  },

  computed: {
    ...mapState('infoConstructor', ['additionalInfoSelectedEventType']),
    ...mapGetters('infoConstructor', ['getCreatedAdditionalInfoArticleId']),

    currentCompanyId() {
      return this.$route.params.id
    },
    eventTypeId() {
      return this.$route.params.eventTypeId
    },
    eventId() {
      return this.$route.query.eventId
    },
    isEditable() {
      return this.$route.hash === '#edit'
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {},
  methods: {
    ...mapActions('infoConstructor', [
      'loadSelectedAdditionalInfoEvent',
      'loadSelectedAdditionalInfoEventType',
      'createNewAdditionalInfoEvent',
      'updateSelectedAdditionalInfoEvent',
    ]),
    ...mapActions('logs', ['displayWarningAlert']),

    async initialize() {
      if (this.currentCompanyId !== 'undefined') {
        await this.loadSelectedAdditionalInfoEventType(this.eventTypeId)
        if (this.isEditable) await this.loadSelectedAdditionalInfoEvent(this.eventId)
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    getPackage(val) {
      this.selectedPackage = val
    },
    async saveData(event) {
      if (this.isEditable) {
        const payload = {
          eventId: this.eventId,
          updatedEvent: event,
        }
        delete payload.updatedEvent.id
        await this.updateSelectedAdditionalInfoEvent(payload)
      } else {
        await this.createNewAdditionalInfoEvent(event)
      }
      /*await this.$router.push(
        `/spa/company/${this.currentCompanyId}/${this.ROUTER_LINKS.INFO_CONSTRUCTOR_EVENTS_LIST}/${this.eventTypeId}`
      )*/
      await this.$router.go(-1)
    },
  },
}
</script>

<style scoped></style>
