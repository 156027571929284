import { PACKAGE_TYPES } from '@/const/constructor/packages-types.enum'

export default {
  filters: {
    getPackageTitle(val) {
      const currentPackage = PACKAGE_TYPES.filter((pkg) => pkg.type === val)
      if (currentPackage.length) {
        return currentPackage[0].title
      } else return val
    },
    getPackageDesc1(val) {
      const currentPackage = PACKAGE_TYPES.filter((pkg) => pkg.type === val)
      if (currentPackage.length) {
        return currentPackage[0].desc1
      } else return val
    },
    getPackageDesc2(val) {
      const currentPackage = PACKAGE_TYPES.filter((pkg) => pkg.type === val)
      if (currentPackage.length) {
        return currentPackage[0].desc2
      } else return val
    },
    getPackageDesc3(val) {
      const currentPackage = PACKAGE_TYPES.filter((pkg) => pkg.type === val)
      if (currentPackage.length) {
        return currentPackage[0].desc3
      } else return val
    },
  },
}
